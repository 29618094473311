import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants/base.const";
import { Role } from "../../model/role.model";

interface RoleSlice {
  roles: Role[];
}

export const loadRoles = createAsyncThunk(
  "role/loadRoles",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/settings/role`);
      return data;
    }
    catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState: {
    roles: [],
  } as RoleSlice,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(loadRoles.fulfilled, (state, { payload }) => {
        state.roles = payload as Role[];
      });
  },
});

export const { } = roleSlice.actions;

export default roleSlice.reducer;
