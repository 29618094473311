import React from "react";
import { Noop } from "react-hook-form";

interface Props {
  name: string,
  label: string,
  onChange: (...event: any[]) => void,
  onBlur: Noop,
  type: React.HTMLInputTypeAttribute | undefined,
  disabled?: boolean,
  placeholder: string,
  value: string | number,
}
const InputField = ({
  name,
  label,
  onChange,
  onBlur,
  type,
  disabled = false,
  placeholder,
  value,
}: Props) => {
  return (
    <div className="w-full">
      <label className="inputLabel" htmlFor={name}>
        {label}
      </label>
      <input
        type={type || "text"}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        className="inputField mt-1"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default InputField;
