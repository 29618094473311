import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import InputField from "../../components/common/InputField";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import { LoginUserProps } from "../../model/user.model";
import { clearState, loginUser } from "../../store/slices/userSlice";

export default function Login() {
  const dispatch = useAppDispatch();

  const { isError, errorMessage } = useAppSelector(state => state.user);

  const {
    control,
    handleSubmit,
  } = useForm<LoginUserProps>();

  const onSubmit = (data: LoginUserProps) => {
    dispatch(loginUser(data));
  };

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, errorMessage, isError]);

  return (
    <div className="flex w-full bg-login items-center justify-center min-h-screen">
      <div className="bg-white w-full lg:max-w-xl p-8 rounded-md shadow-lg flex items-center flex-col">
        <img src="/logo.png" alt="" />
        <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-4 space-y-4">
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field: { onChange, value, onBlur } }) => (
              <InputField
                name="email"
                label="Email"
                type="text"
                placeholder="Enter your email address"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            defaultValue=""
            render={({ field: { onChange, value, onBlur } }) => (
              <InputField
                name="password"
                label="Password"
                type="password"
                placeholder="Enter password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          <p className="text-yellow-500 text-sm mt-3 text-end underline hover:text-yellow-600">
            Forgot Password?
          </p>
          <span className="w-full flex justify-center mt-2">
            <button className="btnPrimary px-7" type="submit">
              Login
            </button>
          </span>
        </form>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}
