export enum SettingCategory {
  Industry = 'industry',
  Hours = 'hours',
  Membership = 'membership',
  Rating = 'rating',
  Source = 'source',
  Status = 'status',
}

export enum Permission {
  Settings = "settings",
  MerchantsSetting = "setting-merchants",
  UsersSetting = "setting-users",
  RolesSetting = "setting-roles",
  IndustrySetting = "setting-industry",
  HoursSetting = "setting-hours",
  MembershipSetting = "setting-membership",
  RatingSetting = "setting-rating",
  StatusSetting = "setting-status",
}