import InputField from "../../components/common/InputField";

export default function SearchResetForm() {
  return (
    <form className="grid lg:grid-cols-4 gap-3 gap-y-2 mt-3">
      <InputField
        name="full name"
        label="Full Name"
        placeholder="Enter Merchant Name"
      />
      <InputField
        name="company name"
        label="Company Name"
        placeholder="Company Name"
      />
      <InputField name="address" label="Address" placeholder="Address" />
      <InputField name="street" label="Street" placeholder="Street" />
      <InputField name="zip" label="Zip" placeholder="Zip" />
      <InputField
        name="email"
        label="Email Address"
        placeholder="Email Address"
        type="email"
      />
      <div />
      <div className="flex items-center justify-end gap-3">
        <button className="btnPrimary">Reset</button>
        <button className="btnPrimary">Search</button>
      </div>
    </form>
  );
}
