import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function SelectWebsite({
  options,
  label,
  name,
  handleSelect,
  className,
}) {
  const [selected, setSelected] = useState(options[0].name);
  const [query, setQuery] = useState("");

  const filteredoptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="z-10">
      <label className="inputLabel" htmlFor={name}>
        {label}{" "}
      </label>
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="flex items-center bg-white">
            <Combobox.Input
              className={`inputField ${className}`}
              displayValue={(option) => option}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredoptions.length === 0 && query !== "" ? (
                <div className="relative select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredoptions.map((option, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) =>
                      `relative select-none pl-10 border-b pr-4 ${
                        active ? "bg-gray-100" : "text-gray-900"
                      }`
                    }
                    value={option.name}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          onClick={() => handleSelect(option.id)}
                          className={`block truncate cursor-pointer py-2 ${ 
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "" : "text-gray-100"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
