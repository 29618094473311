import { Checkbox } from "@mui/material";
import {
  GridColDef,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp
} from "@mui/x-data-grid-pro";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import CrudGrid from "../../components/CrudGrid";
import { API_URL } from "../../constants/base.const";
import { Permission } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";

export default function Role() {
  const { roles } = useAppSelector(state => state.role);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  // permissions module state
  const [permissionByPermissionName, setPermissionByPermissionName] = useState<{ [permission: string]: boolean; }>({});

  useEffect(() => {
    const obj = { ...permissionByPermissionName };

    Object.values(Permission).map(permission => {
      const selectedRole = roles.find(role => selectedRowIds.includes(role.id));
      const checked = !!selectedRole?.permissions.includes(permission);
      obj[permission] = checked;
    });

    setPermissionByPermissionName(obj);
  }, [selectedRowIds]);

  const handleClickAddRole = () => {
    // add new row
    const id = new Date().getTime() % 100000000;
    setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  const handleChangePermissionCheckbox = (permission: Permission, checked: boolean) => {
    setPermissionByPermissionName({
      ...permissionByPermissionName,
      [permission]: checked
    });
  };

  const handleSavePermissions = async () => {
    try {
      const selectedRole = roles.find(role => selectedRowIds.includes(role.id));
      if (selectedRole) {
        await axios.patch(
          `${API_URL}/settings/role/${selectedRowIds[0]}`,
          {
            ...selectedRole,
            permissions: Object.values(Permission).filter(permission => permissionByPermissionName[permission])
          }
        );
        toast.success("Saved successfully.");
      }
    }
    catch (e: any) {
      console.log(e);
      toast.error(e?.message);
    }
  };

  const columnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "ID",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Role Name",
      editable: true,
      type: "string",
      flex: 1
    },
  ];

  // initialize grid with data from server
  useEffect(() => {
    setLoadingTable(true);
    axios
      .get(`${API_URL}/settings/role`)
      .then((response) => {
        const rowsWithId = response.data.map(
          (row: GridRowModel, index: number) => ({
            ...row,
            customId: index + 1,
          })
        );
        setRows(rowsWithId);
        setLoadingTable(false);
      });
  }, []);

  const processRowUpdate = async (
    newRow: GridRowModel,
  ) => {
    setLoadingTable(true);
    const payload = { ...newRow, customId: undefined, isNew: undefined };

    try {
      // update
      if (!newRow.isNew) {
        const { status: updateStatus } = await axios.patch(
          `${API_URL}/settings/role/${newRow.id}`,
          payload
        );
        updateStatus === 201 &&
          setRows(rows.map((row) => newRow.id === row.id ? newRow : row));
      }
      // create
      else {
        const { status: createStatus, data } = await axios.post(
          `${API_URL}/settings/role`,
          { ...payload, id: undefined, permissions: [] }
        );
        createStatus === 201 &&
          setRows(rows.map((row) => row.id === newRow.id
            ? { ...data, customId: rows.length }
            : row
          ));
      }
      setLoadingTable(false);
      return newRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  };

  return (
    <div className="grid grid-cols-2 gap-6 mt-2">
      <div className="bg-white rounded-md w-full p-6 shadow-sm">
        <div className="flex items-center justify-between gap-2">
          <h2 className="elementHeading4">Manage Roles</h2>
          <button className="btnPrimary" onClick={handleClickAddRole}>Add Role</button>
        </div>
        <div className="w-full overflow-x-scroll mt-8">
          <CrudGrid
            baseApiPath="settings/role"
            columnDefs={columnDefs}
            disableMultipleRowSelection={true}
            editable={true}
            loading={loadingTable}
            rowModesModel={rowModesModel}
            rows={rows}
            processRowUpdate={processRowUpdate}
            setLoading={setLoadingTable}
            setRowModesModel={setRowModesModel}
            setRows={setRows}
            setSelectedRowIds={setSelectedRowIds}
          />
        </div>
      </div>

      <div className="bg-white rounded-md w-full p-6 shadow-sm">
        <div className="flex items-center justify-between gap-2">
          <h2 className="elementHeading4">
            Manage Permissions
          </h2>
          <button
            className="btnPrimary"
            onClick={handleSavePermissions}
            disabled={selectedRowIds.length === 0}
          >
            Save Permissions
          </button>
        </div>
        <div>
          <table className="min-w-full divide-y divide-gray-200 mt-5">
            <thead className="bg-gray-50 sticky top-0 shadow-sm ">
              <tr>
                <th scope="col" className="tableHead">
                  Section.
                </th>
                <th scope="col" className="tableHead">
                  View
                </th>
                <th scope="col" className="tableHead">
                  Edit
                </th>
                <th scope="col" className="tableHead">
                  Delete
                </th>
                <th scope="col" className="tableHead">
                  All
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {Object.values(Permission).map(permission => {
                return (
                  <tr key={permission}>
                    <td>{permission}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Checkbox
                        checked={!!permissionByPermissionName[permission]}
                        onChange={e => handleChangePermissionCheckbox(permission, e.target.checked)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
