import { useRef, useState } from "react";
import { Modal, InputField } from "../../components/common";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";

export default function Status() {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <>
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-2xl"
      >
        <InputField
          name="User status"
          label="User Status"
          placeholder="Enter User Status"
        />
      </Modal>
      <div className="flex items-center justify-between mt-3 mb-8">
        <h1 className="elementHeading2">Status</h1>
        <div className="flex items-center justify-end space-x-2">
          <button onClick={handleModal} className="btnPrimary">
            Create Status
          </button>
        </div>
      </div>
      <table able className="min-w-full divide-y divide-gray-200 mt-5">
        <thead className="bg-gray-50 sticky top-0 shadow-sm ">
          <tr>
            <th scope="col" className="tableHead">
              Id.
            </th>
            <th scope="col" className="tableHead">
              Job name
            </th>
            <th scope="col" className="tableHead">
              Job status
            </th>
            <th scope="col" className="tableHead">
              actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr className="hover:bg-gray-100">
            <td className="px-6 py-4">1</td>
            <td className="px-6 py-4">Towing</td>
            <td className="px-6 py-4">Active</td>
            <td className="px-6 py-4">
              <span className="flex items-center w-full">
                <button className="text-gray-400 hover:text-yellow-500">
                  <PencilSquareIcon className="h-4" />
                </button>
                <button className="ml-2 text-gray-400 hover:text-red-400">
                  <TrashIcon className="h-4" />
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
