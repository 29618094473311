import {
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp
} from "@mui/x-data-grid-pro";
import axios from "axios";
import Papa from "papaparse";
import React, {
  useEffect,
  useState
} from "react";
import toast from "react-hot-toast";
import validator from "validator";

import CrudGrid from "../../components/CrudGrid";
import EditToolbar from "../../components/EditToolbar";
import { API_URL } from "../../constants/base.const";
import { SettingCategory } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";

export default function Vendors() {
  const {
    hoursOptions,
    industryOptions,
    membershipOptions,
    ratingOptions,
    sourceOptions,
    statusOptions,
  } = useAppSelector((state) => state.settings);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const columnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "ID",
      editable: false,
      type: "string",
      width: 80,
      headerAlign: "left",
    },
    {
      field: "industry",
      headerName: "Industry",
      editable: true,
      type: "singleSelect",
      valueGetter: (value) => value?.value?.id || "",
      valueSetter: (value) => ({
        ...value?.row,
        industry: { id: value?.value },
      }),
      valueOptions: industryOptions.map((opt) => ({
        value: opt.id,
        label: opt.description,
      })),
    },
    {
      field: "source",
      headerName: "Source",
      editable: true,
      type: "singleSelect",
      valueGetter: (value) => value?.value?.id || "",
      valueSetter: (value) => ({ ...value?.row, source: { id: value?.value } }),
      valueOptions: sourceOptions.map((opt) => ({
        value: opt.id,
        label: opt.description,
      })),
    },
    {
      field: "vendor",
      headerName: "Vendor",
      editable: true,
      type: "string",
      width: 250,
    },
    { field: "address", headerName: "Address", editable: true, type: "string" },
    {
      field: "state",
      headerName: "State",
      editable: true,
      type: "string",
      width: 80,
    },
    {
      field: "zip",
      headerName: "Zip",
      editable: true,
      type: "string",
      width: 80,
    },
    {
      field: "vendorWebsite",
      headerName: "Website",
      editable: true,
      type: "string",
      width: 350,
    },
    {
      field: "phoneNumber",
      headerName: "Vendor #",
      editable: true,
      type: "string",
    },
    {
      field: "forwardingNumber",
      headerName: "Forwarding #",
      editable: true,
      type: "string",
    },
    { field: "email", headerName: "Email", editable: true, type: "string" },
    {
      field: "password",
      headerName: "Password",
      editable: true,
      type: "string",
    },
    {
      field: "firstName",
      headerName: "FirstName",
      editable: true,
      type: "string",
    },
    {
      field: "lastName",
      headerName: "LastName",
      editable: true,
      type: "string",
    },
    { field: "city", headerName: "City", editable: true, type: "string" },
    {
      field: "hours",
      headerName: "Hours",
      editable: true,
      type: "singleSelect",
      valueGetter: (value) => value?.value?.id || "",
      valueSetter: (value) => ({ ...value?.row, hours: { id: value?.value } }),
      valueOptions: hoursOptions.map((opt) => ({
        value: opt.id,
        label: opt.description,
      })),
    },
    {
      field: "rating",
      headerName: "Rating",
      editable: true,
      type: "singleSelect",
      valueGetter: (value) => value?.value?.id || "",
      valueSetter: (value) => ({ ...value?.row, rating: { id: value?.value } }),
      valueOptions: ratingOptions.map((opt) => ({
        value: opt.id,
        label: opt.description,
      })),
    },
    {
      field: "membership",
      headerName: "Membership",
      editable: true,
      type: "singleSelect",
      valueGetter: (value) => value?.value?.id || "",
      valueSetter: (value) => ({
        ...value?.row,
        membership: { id: value?.value },
      }),
      valueOptions: membershipOptions.map((opt) => ({
        value: opt.id,
        label: opt.description,
      })),
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueGetter: (value) => value?.value?.id || "",
      valueSetter: (value) => ({ ...value?.row, status: { id: value?.value } }),
      valueOptions: statusOptions.map((opt) => ({
        value: opt.id,
        label: opt.description,
      })),
    },
  ];

  useEffect(() => {
    // initialize grid with data from server
    setLoadingTable(true);
    axios
      .get(
        `${API_URL}/vendor?${Object.values(SettingCategory)
          .map((v) => "join=" + v)
          .join("&")}`
      )
      .then((response) => {
        const rowsWithId = response.data.map(
          (row: GridRowModel, index: number) => ({
            ...row,
            customId: "VN" + (125000 + index),
          })
        );
        setRows(rowsWithId);
        setLoadingTable(false);
      });
  }, []);

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    // format category fields
    Object.values(SettingCategory).forEach((category) => {
      const categoryId = newRow[category]?.id;
      if (+categoryId === 0) {
        newRow[category] = undefined;
      }
    });

    // updated field names
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;

    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];

    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }

    try {
      // update
      if (updatedRows.length) {
        const { status: updateStatus } = await axios.post(
          `${API_URL}/vendor/updateMany`,
          updatedRows.map((u) => ({ ...u, customId: undefined }))
        );
        updateStatus === 201 &&
          setRows(
            rows.map(
              (row) =>
                updatedRows.filter((urow) => urow.id === row.id).at(0) || row
            )
          );
      }
      // create
      if (createdRows.length === 1) {
        const { status: createStatus, data } = await axios.post(
          `${API_URL}/vendor`,
          createdRows.at(0)
        );
        createStatus === 201 &&
          setRows(
            rows.map((row) =>
              row.id === updatedRow.id
                ? { ...data, customId: "VN" + (125000 + rows.length - 1) }
                : row
            )
          );
      }
      setLoadingTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || loadingTable) return;
    if (e.target.files.length === 0) return;
    setLoadingTable(true);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const parsedData = results.data as GridRowsProp;
        const isValidData = parsedData.every((obj: any, objIndex) => {
          return columnDefs.every((column) => {
            const columnName = column.field;
            if (columnName === "action") return true;
            const dataType = column.type;
            const value = obj[columnName];
            // Check if the object has the correct property and data type
            if (dataType === "string" && typeof value === "string") {
              if (columnName === "email" && !validator.isEmail(value)) {
                obj[columnName] = null;
              } else if (
                columnName === "zip" &&
                !validator.isPostalCode(value, "US")
              ) {
                obj[columnName] = null;
              }
              return true;
            } else if (dataType === "singleSelect") {
              obj[columnName] =
                typeof +value === "number" && +value > 0
                  ? {
                    id: +value,
                  }
                  : null;
              return true;
            } else if (dataType === "number" && columnName === "customId") {
              obj[columnName] = undefined;
              return true;
            } else {
              console.log("obj, columnName", obj, columnName);
              return false;
            }
          });
        });

        if (isValidData) {
          try {
            const { data } = await axios.post(`${API_URL}/vendor/bulk`, {
              bulk: parsedData,
            });
            const dataWithCustomId = data.map(
              (d: GridRowModel, dIdx: number) => ({
                ...d,
                customId: "VN" + (125000 + rows.length + dIdx),
              })
            );
            setRows([...rows, ...dataWithCustomId]);
          } catch (e: any) {
            console.log(e);
            toast.error(e.message);
          }
        } else {
          toast.error("Invalid csv file.");
        }
        setLoadingTable(false);
      },
    });
  };

  return (
    <>
      <h1 className="text-2xl font-bold">Vendors</h1>
      <div className="bg-white mt-3">
        <CrudGrid
          baseApiPath="vendor"
          checkboxSelection={true}
          columnDefs={columnDefs}
          editable={true}
          loading={loadingTable}
          pinActions={true}
          rowModesModel={rowModesModel}
          rows={rows}
          handleFileUpload={handleFileUpload}
          processRowUpdate={processRowUpdate}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
          setSelectedRowIds={setSelectedRowIds}
          slots={{
            toolbar: EditToolbar,
          }}
        />
      </div>
    </>
  );
}
