import {
  HiClock,
  HiCog,
  HiDatabase,
  HiGlobe,
  HiStar,
  HiTag,
  HiUserCircle,
  HiUserGroup,
  HiUsers,
  HiViewGrid
} from "react-icons/hi";
import { Permission } from "../../constants/base.enum";
import { MenuItem } from "../../model/side-menu.model";

export const sideMenu: Array<MenuItem> = [
  {
    to: "/dashboard",
    label: "Dashboard",
    Icon: HiViewGrid,
  },
  {
    to: "/dashboard/vendors",
    label: "Vendors",
    Icon: HiUsers,
  },
  {
    to: "/dashboard/websites",
    label: "Websites",
    Icon: HiGlobe,
  },
  {
    to: "/dashboard/settings",
    label: "Settings",
    Icon: HiCog,
    permission: Permission.Settings,
    children: [
      {
        to: "/dashboard/settings/merchants",
        label: "Merchants",
        Icon: HiUsers,
        permission: Permission.MerchantsSetting
      },
      {
        to: "/dashboard/settings/users",
        label: "Users",
        Icon: HiUserGroup,
        permission: Permission.UsersSetting
      },
      {
        to: "/dashboard/settings/roles",
        label: "Roles",
        Icon: HiUserCircle,
        permission: Permission.RolesSetting
      },
      {
        to: "/dashboard/settings/industry",
        label: "Industry",
        Icon: HiDatabase,
        permission: Permission.IndustrySetting
      },
      {
        to: "/dashboard/settings/hours",
        label: "hours",
        Icon: HiClock,
        permission: Permission.HoursSetting
      },
      {
        to: "/dashboard/settings/membership",
        label: "membership",
        Icon: HiUserCircle,
        permission: Permission.MembershipSetting
      },
      {
        to: "/dashboard/settings/rating",
        label: "rating",
        Icon: HiStar,
        permission: Permission.RatingSetting
      },
      {
        to: "/dashboard/settings/status",
        label: "status",
        Icon: HiTag,
        permission: Permission.StatusSetting
      },
    ],
  },
];
