import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Permission } from "./constants/base.enum";
import { useAppDispatch, useAppSelector } from "./hooks/rtkHooks";
import { loadRoles } from "./store/slices/roleSlice";
import { loadSettings } from "./store/slices/settingsSlice";
import { fetchUserBytoken } from "./store/slices/userSlice";
import ThemeCustomization from "./themes";
import { getStorageValue } from "./util/localStorage.util";
import {
  Dashboard,
  Hours,
  Industry,
  Membership,
  Merchant,
  Rating,
  Status,
  Role,
  Users,
  Vendors,
  Websites,
} from "./views";

function App() {
  const dispatch = useAppDispatch();
  const { isSuccess, isFetching, userPermissions } = useAppSelector(state => state.user);

  const token = getStorageValue("token");
  let routes;
  if (isSuccess ) {
    routes = [
      { path: "/dashboard", element: <h1>Dashboard</h1> },
      userPermissions.includes(Permission.MerchantsSetting) ?
        ({ path: "/dashboard/settings/merchants", element: <Merchant /> })
        : undefined,
      userPermissions.includes(Permission.UsersSetting) ?
        ({ path: "/dashboard/settings/users", element: <Users /> })
        : undefined,
      userPermissions.includes(Permission.IndustrySetting) ?
        ({ path: "/dashboard/settings/industry", element: <Industry /> })
        : undefined,
      userPermissions.includes(Permission.RolesSetting) ?
        ({ path: "/dashboard/settings/roles", element: <Role /> })
        : undefined,
      userPermissions.includes(Permission.HoursSetting) ?
        ({ path: "/dashboard/settings/hours", element: <Hours /> })
        : undefined,
      userPermissions.includes(Permission.MembershipSetting) ?
        ({ path: "/dashboard/settings/membership", element: <Membership /> })
        : undefined,
      userPermissions.includes(Permission.RatingSetting) ?
        ({ path: "/dashboard/settings/rating", element: <Rating /> })
        : undefined,
      userPermissions.includes(Permission.StatusSetting) ?
        ({ path: "/dashboard/settings/status", element: <Status /> })
        : undefined,
      { path: "/dashboard/websites", element: <Websites /> },
      { path: "/dashboard/vendors", element: <Vendors /> },
      { path: "*", element: <Navigate to="/dashboard" replace /> },
    ];
  } else {
    routes = [{ path: "*", element: <Navigate to="/" replace /> }];
  }

  useEffect(() => {
    dispatch(loadSettings());
    dispatch(loadRoles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserBytoken({ token }));
  }, [dispatch, token]);

  return (
    <ThemeCustomization>
      <BrowserRouter>
        <Dashboard isLoading={isFetching} loggedIn={isSuccess}>
          <Routes>
            {routes?.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Routes>
        </Dashboard>
      </BrowserRouter>
    </ThemeCustomization>
  );
}

export default App;
