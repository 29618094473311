import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp
} from "@mui/x-data-grid-pro";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import CrudGrid from "../../components/CrudGrid";
import { API_URL } from "../../constants/base.const";
import { useAppSelector } from "../../hooks/rtkHooks";

export default function Users() {
  const { roles } = useAppSelector(state => state.role);

  // all users
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  // selected user's role
  const [selectedUserRoleId, setSelectedUserRoleId] = useState(0);

  const columnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "ID",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Username",
      editable: false,
      type: "string",
      flex: 1
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      type: "string",
      flex: 1
    },
    {
      field: "roles",
      headerName: "Role Name",
      editable: false,
      type: "string",
      valueFormatter: ({ value }) => value?.at(0)?.name || "",
      flex: 1
    }
  ];

  // load users only in this component
  useEffect(() => {
    setLoadingTable(true);
    axios
      .get(`${API_URL}/users?join=roles`)
      .then(({ data }) => {
        const rowsWithId = data.map(
          (row: GridRowModel, index: number) => ({
            ...row,
            customId: index + 1,
          })
        );
        setRows(rowsWithId);
        setLoadingTable(false);
      });
  }, []);

  /* ========== [this codes will be used IF one user can have multiple roles] ========== */
  // roles state of selected user
  // const [roleByRoleName, setRoleByRoleName] = useState<{ [roleName: string]: boolean; }>({});

  // useEffect(() => {
  //   const obj = { ...roleByRoleName };

  //   roles.map(role => {
  //     const selectedUser = rows.find(row => selectedRowIds.includes(row.id));
  //     const checked = selectedUser ? !!selectedUser.roles.filter((userRole: Role) => userRole.name === role.name)?.length : false;
  //     obj[role.name] = checked;
  //   });

  //   setRoleByRoleName(obj);
  // }, [selectedRowIds]);

  // const handleChangeRole = (roleName: string, checked: boolean) => {
  //   setRoleByRoleName({
  //     ...roleByRoleName,
  //     [roleName]: checked
  //   });
  // };

  useEffect(() => {
    const selectedUser = rows.find(row => selectedRowIds.includes(row.id));
    if (selectedUser) {
      setSelectedUserRoleId(selectedUser.roles[0]?.id || 0);
    }
  }, [rows, selectedRowIds]);

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUserRoleId(+(event.target as HTMLInputElement).value);
  };

  const handleSaveRoles = async () => {
    try {
      if (selectedRowIds[0]) {
        await axios.patch(
          `${API_URL}/users/${selectedRowIds[0]}`,
          {
            roles: selectedUserRoleId === 0 ? [] : [selectedUserRoleId],
            customId: undefined,
            createdAt: undefined,
            updatedAt: undefined
          }
        );
        setRows(rows.map(row => row.id === selectedRowIds[0] ? ({
          ...row, roles: roles.filter(role => role.id === selectedUserRoleId)
        }) : row));
        toast.success("Saved successfully.");
      }
    }
    catch (e: any) {
      console.log(e);
      toast.error(e?.message);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-6 mt-2">
      <div className="bg-white rounded-md w-full p-6 shadow-sm">
        <div className="flex items-center justify-between gap-2">
          <h2 className="elementHeading4">User List</h2>
        </div>
        <div className="w-full overflow-x-scroll mt-8">
          <CrudGrid
            baseApiPath="users"
            disableMultipleRowSelection={true}
            loading={loadingTable}
            rows={rows}
            rowModesModel={rowModesModel}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            columnDefs={columnDefs}
            setLoading={setLoadingTable}
            setSelectedRowIds={setSelectedRowIds}
          />
        </div>
      </div>

      <div className="bg-white rounded-md w-full p-6 shadow-sm">
        <div className="flex items-center justify-between gap-2">
          <h2 className="elementHeading4">
            Manage User Roles
          </h2>
          <button
            className="btnPrimary"
            onClick={handleSaveRoles}
            disabled={selectedRowIds.length === 0}
          >
            Save Roles
          </button>
        </div>
        <div>
          <RadioGroup
            name="user-roles-group"
            value={selectedUserRoleId}
            onChange={handleChangeRole}
          >
            {roles.map(role => {
              return (
                <FormControlLabel key={role.id} value={role.id} control={<Radio />} label={role.name} />
              );
            })}
            <FormControlLabel key={"none"} value={0} control={<Radio />} label="None" />
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}
