import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Dialog, DialogActions, DialogTitle
} from "@mui/material";
import { Box } from "@mui/system";
import {
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  MuiEvent
} from "@mui/x-data-grid";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowSelectionModel,
  UncapitalizedGridProSlotsComponent,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { Button } from "antd";
import axios from "axios";
import React, { useState } from "react";

import { API_URL } from "../../constants/base.const";

interface Props {
  baseApiPath: string;
  checkboxSelection?: boolean;
  columnDefs: GridColDef[];
  disableMultipleRowSelection?: boolean;
  editable?: boolean;
  loading: boolean;
  pinActions?: boolean;
  rows: GridRowsProp;
  rowModesModel: GridRowModesModel;
  slots?: Partial<UncapitalizedGridProSlotsComponent>;
  handleFileUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  processRowUpdate?: (newRow: GridRowModel, oldRow: GridRowModel) => Promise<GridValidRowModel | undefined>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  setRows: React.Dispatch<React.SetStateAction<readonly GridValidRowModel[]>>;
  setSelectedRowIds?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
}

export default function CrudGrid({
  baseApiPath,
  checkboxSelection,
  columnDefs,
  disableMultipleRowSelection,
  editable,
  loading,
  pinActions,
  rows,
  rowModesModel,
  slots,
  handleFileUpload,
  processRowUpdate,
  setLoading,
  setRowModesModel,
  setRows,
  setSelectedRowIds,
}: Props) {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [rowIdToDelete, setRowIdToDelete] = useState<GridRowId>(0);

  const apiRef = useGridApiRef();

  const actionColDef: GridColDef = {
    field: "action",
    headerName: "Action",
    type: "actions",
    cellClassName: "actions",
    disableColumnMenu: false,
    width: 120,
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      const actionItems = [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />
      ];

      if (editable) {
        actionItems.push(
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        );
      }

      return actionItems.reverse();
    },
  };

  const columns: GridColDef[] = pinActions ? ([actionColDef] as GridColDef[]).concat(columnDefs)
    : columnDefs.concat([actionColDef] as GridColDef[]);

  /* const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Profile',
      children: [{ field: 'firstName' }, { field: 'lastName' }, { field: 'email' }, { field: 'password' }],
    },
  ]; */
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => {
    setRowIdToDelete(id);
    setShowDeletePrompt(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowEditStart = (
    _params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    _params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 200px)",
        width: "100%",
        "& .actions": {
          color: "#263238",
        },
        "& .textPrimary": {
          color: "#263238",
        },
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        editMode="row"
        loading={loading}
        checkboxSelection={!!checkboxSelection}
        disableMultipleRowSelection={!!disableMultipleRowSelection}
        rowModesModel={rowModesModel}
        rowHeight={40}
        columnHeaderHeight={36}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        onRowSelectionModelChange={(
          rowSelectionModel: GridRowSelectionModel
        ) => {
          setSelectedRowIds && setSelectedRowIds(rowSelectionModel);
        }}
        processRowUpdate={processRowUpdate}
        slots={slots}
        slotProps={{
          toolbar: {
            rowModesModel,
            setRows,
            setRowModesModel,
            handleFileUpload,
            disable: loading,
          },
        }}
        onProcessRowUpdateError={console.error}
        classes={{
          columnHeader: "bg-gray-50 shadow-sm",
          columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
          row: "text-md"
        }}
        apiRef={apiRef}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
      // experimentalFeatures={{ columnGrouping: true }}
      // columnGroupingModel={columnGroupingModel}
      />
      <Dialog
        open={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete record?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDeletePrompt(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              try {
                setShowDeletePrompt(false);
                setLoading(true);
                await axios.delete(`${API_URL}/${baseApiPath}/${rowIdToDelete}`);
                setRows(rows.filter((row) => row.id !== rowIdToDelete));
              } catch (e) {
                console.log(e);
              }
              setLoading(false);
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
