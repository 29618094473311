import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants/base.const";
import { SettingCategory } from "../../constants/base.enum";

type SettingOption = {
  id: number,
  description: string;
};

interface SettingsSlice {
  industryOptions: SettingOption[],
  hoursOptions: SettingOption[],
  membershipOptions: SettingOption[],
  ratingOptions: SettingOption[],
  sourceOptions: SettingOption[],
  statusOptions: SettingOption[],
}

export const loadSettings = createAsyncThunk(
  "settings/loadSettings",
  async (_, { dispatch }) => {
    const { data: industryOptions } = await axios.get(`${API_URL}/settings/industry`);
    const { data: hoursOptions } = await axios.get(`${API_URL}/settings/hours`);
    const { data: membershipOptions } = await axios.get(`${API_URL}/settings/membership`);
    const { data: ratingOptions } = await axios.get(`${API_URL}/settings/rating`);
    const { data: sourceOptions } = await axios.get(`${API_URL}/settings/source`);
    const { data: statusOptions } = await axios.get(`${API_URL}/settings/status`);

    dispatch(updateSettingOptionsByCategory({ category: SettingCategory.Industry, newOptions: industryOptions }));
    dispatch(updateSettingOptionsByCategory({ category: SettingCategory.Hours, newOptions: hoursOptions }));
    dispatch(updateSettingOptionsByCategory({ category: SettingCategory.Membership, newOptions: membershipOptions }));
    dispatch(updateSettingOptionsByCategory({ category: SettingCategory.Rating, newOptions: ratingOptions }));
    dispatch(updateSettingOptionsByCategory({ category: SettingCategory.Source, newOptions: sourceOptions }));
    dispatch(updateSettingOptionsByCategory({ category: SettingCategory.Status, newOptions: statusOptions }));
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    industryOptions: [],
    hoursOptions: [],
    membershipOptions: [],
    ratingOptions: [],
    sourceOptions: [],
    statusOptions: [],
  } as SettingsSlice,
  reducers: {
    updateSettingOptionsByCategory(state, { payload }: PayloadAction<{
      category: SettingCategory,
      newOptions: SettingOption[],
    }>) {
      switch (payload.category) {
        case SettingCategory.Industry: state.industryOptions = payload.newOptions; break;
        case SettingCategory.Hours: state.hoursOptions = payload.newOptions; break;
        case SettingCategory.Membership: state.membershipOptions = payload.newOptions; break;
        case SettingCategory.Rating: state.ratingOptions = payload.newOptions; break;
        case SettingCategory.Source: state.sourceOptions = payload.newOptions; break;
        case SettingCategory.Status: state.statusOptions = payload.newOptions; break;
      }
    },
  }
});

export const { updateSettingOptionsByCategory } = settingsSlice.actions;

export default settingsSlice.reducer;
