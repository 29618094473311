import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import SearchResetForm from "./SearchResetForm";
import { useState, useRef } from "react";
import { Modal } from "../../components/common";

export default function Merchant() {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <>
      <SearchResetForm />
      <div className="flex items-center justify-between mt-5">
        <h1 className="elementHeading2">Merchants</h1>
        <button className="btnPrimary" onClick={handleModal}>
          Create Merchant
        </button>
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-2xl"
        />
      </div>
      <div className="w-full overflow-x-scroll h-[600px] mt-4">
        {" "}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 shadow-sm ">
            <tr>
              <th scope="col" className="tableHead">
                Id.
              </th>
              <th scope="col" className="tableHead">
                merchant name
              </th>
              <th scope="col" className="tableHead">
                company
              </th>
              <th scope="col" className="tableHead">
                address
              </th>
              <th scope="col" className="tableHead">
                street
              </th>
              <th scope="col" className="tableHead">
                zip
              </th>
              <th scope="col" className="tableHead">
                email
              </th>
              <th scope="col" className="tableHead">
                actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr className="hover:bg-gray-100">
              <td className="px-6 py-4">1</td>
              <td className="px-6 py-4">TowingNearMe</td>
              <td className="px-6 py-4">TowingNearMe</td>
              <td className="px-6 py-4">Lorem Ipsum</td>
              <td className="px-6 py-4">Test Street</td>
              <td className="px-6 py-4">35540</td>
              <td className="px-6 py-4">test@towing.com</td>
              <td className="px-6 py-4 flex items-center justify-center">
                <button className="text-gray-400 hover:text-yellow-500">
                  <PencilSquareIcon className="h-4" />
                </button>
                <button className="ml-2 text-gray-400 hover:text-red-400">
                  <TrashIcon className="h-4" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
