import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { IconType } from "react-icons";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/rtkHooks";
import { MenuItem } from "../../model/side-menu.model";

interface Props {
  label: string,
  options: MenuItem[] | undefined,
  Icon: IconType | undefined,
  show_navlabel: boolean,
  to: string,
  activeMenuItem: string,
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string>>;
}

export default function Dropdown(props: Props) {
  const {
    label,
    options,
    Icon,
    show_navlabel,
    to,
    activeMenuItem,
    setActiveMenuItem,
  } = props;

  const { userPermissions } = useAppSelector(state => state.user);

  return (
    <Menu as="div" className="drop_down_menu">
      <div>
        <Menu.Button
          title={label}
          className="hover:bg-gray-100 p-2 rounded-lg flex items-center focus:outline-none w-full hover:no-underline hover:text-black"
        >
          <div
            className={
              show_navlabel
                ? "flex items-center w-full "
                : "flex items-center w-full justify-center"
            }
          >
            {Icon && <Icon />}
            {/* {show_navlabel && <span className="ml-3">{label}</span>} */}
            <span className={show_navlabel ? "ml-3 capitalize" : "ml-1 capitalize"}>{label}</span>
          </div>
          <FaAngleDown className="ml-1" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            show_navlabel
              ? "dropdown_options bg-beta rounded-xl pl-5 text-black"
              : "dropdown_options_small bg-white shadow-lg p-3 rounded-xl absolute top-0 mt-12"
          }
        >
          {options?.map((item, index) => {
            const isActive = activeMenuItem === item.label;
            const showItem = !!!item?.permission || userPermissions?.includes(item.permission);

            return (
              <div key={index} style={{ display: showItem ? "block" : "none" }}>
                <Link
                  className={`pageLink py-1 ${isActive ? "bg-gray-200 text-black" : ""
                    }`}
                  title={item.label}
                  to={`${item.to}`}
                  onClick={() => setActiveMenuItem(item.label)}
                >
                  {Icon && <item.Icon />}
                  <span className="ml-3 capitalize">{item.label}</span>
                  {/* {show_navlabel && <span className="ml-3">{item.label}</span>} */}
                </Link>
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
