import axios from "axios";
import SelectWebsite from "./SelectWebsite";
import { Modal } from "../../components/common";
import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, useRef, useState, Fragment } from "react";
import {
  TrashIcon,
  PencilSquareIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
interface MyData {
  [key: string]: string | number | boolean;
}

const websites = [
  { id: 0, name: "Select Website" },
  { id: 1, name: "towingnearme.us" },
  { id: 2, name: "handyman.us" },
  { id: 3, name: "garagedoorrepair.us" },
  { id: 4, name: "chimney-sweep.us" },
  { id: 5, name: "carpetcleaning-nearme.us" },
  { id: 6, name: "locksmith-near-me.us" },
  { id: 7, name: "dryervent-cleaning.us" },
  { id: 8, name: "airductcleaning.us" },
  { id: 9, name: "gaterepairnearme.us" },
  { id: 10, name: "plumbersnearme.us" },
  { id: 11, name: "movers-nearme.us" },
  { id: 12, name: "appliance-repair.us" },
  { id: 13, name: "slidingdoor-repair.us" },
  { id: 14, name: "pestcontrol-nearme.us" },
  { id: 15, name: "roofing-nearme.us" },
  { id: 16, name: "firedamage-restoration.com" },
  { id: 17, name: "waterdamage-restoration.com" },
  { id: 18, name: "moldremoval-nearme.com" },
];

const contentType = [
  { name: "All", type: "" },
  { name: "Meta Tags", type: "meta" },
  { name: "videos", type: "video" },
  { name: "phone number", type: "_phone" },
  { name: "services", type: "service_" },
  { name: "faqs", type: "faq" },
  { name: "how to", type: "howto" },
  { name: "footer", type: "footer" },
  { name: "areacode", type: "areacode" },
  { name: "blog", type: "blog_" },
  { name: "text", type: "text" },
  { name: "banner", type: "banner" },
];

const tagsType = [
  { name: "##service##", des: "for opted service" },
  { name: "##state##", des: "for opted state" },
  { name: "##county##", des: "for opted county" },
  { name: "##city##", des: "for opted city" },
  { name: "##zip##", des: "for opted zip_name" },
  { name: "##phone##", des: "per area code" },
  { name: "##area##", des: "for immediate route" },
  { name: "<<tagkey>>", des: "Refference key" },
];

const markdownTags = [
  "home_text",
  "state_text_1",
  "state_text_2",
  "city_text_1",
  "city_text_2",
  "county_text_1",
  "county_text_2",
];

function isMarkdownTag(tag: string) {
  return markdownTags.includes(tag);
}

export default function Websites() {
  const [searchQuery, setSearchQuery] = useState("");
  const [myData, setMyData] = useState<MyData>({});
  const [searchSite, setSearchSite] = useState(1);
  const [loading, setLoading] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [modalType, setModalType] = useState("newKey");
  const [updateKey, setUpdateKey] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");

  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get<MyData>(
        `https://api15.ecommcube.com/_${searchSite}apidata/jtags?pass=billy`
      )
      .then((response) => {
        setMyData(response.data);
      });
    setLoading(false);
  }, [searchSite]);

  const filteredData = Object.entries(myData).filter(([key, value]) => {
    return (
      key.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteKey = (keyToDelete: string) => {
    setModalType("deleteKey");
    setKeyToDelete(keyToDelete);
    handleModal();
  };

  const handleConfirmDelete = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    axios
      .get(
        `https://api15.ecommcube.com/_${searchSite}apidata/jtags_delete?${keyToDelete}&pass=billy`
      )
      .then((response) => {
        setMyData((prevData) => {
          const newData = { ...prevData };
          delete newData[keyToDelete];
          return newData;
        });
        setKeyToDelete("");
        handleModal();
      })
      .catch((error) => {
        console.error(error); // Handle errors as needed
        setKeyToDelete("");
        handleModal();
      });
  };

  const handleCreateNewKey = () => {
    setModalType("newKey");
    handleModal();
  };

  const SubmitNewKey = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Update the key-value pair in the local state
    setMyData((prevData) => ({
      ...prevData,
      [newKey]: newValue,
    }));

    // Make an API call to update the key-value pair on the server
    axios
      .post(
        `https://api15.ecommcube.com/_${searchSite}apidata/jtags_update`,
        null,
        {
          params: {
            [newKey]: newValue,
            pass: "billy",
          },
        }
      )
      .then((response) => {
        console.log(response.data); // Handle the response as needed
      })
      .catch((error) => {
        console.error(error); // Handle errors as needed
      });
    handleModal();
  };

  const handleUpdateKey = (
    key: React.SetStateAction<string>,
    value: React.SetStateAction<string> | string
  ) => {
    setModalType("updateKeyValue");
    setUpdateKey(key);
    setUpdateValue(value);
    handleModal();
  };

  const handleUpdateValue = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Update the key-value pair in the local state
    setMyData((prevData) => ({
      ...prevData,
      [updateKey]: updateValue,
    }));

    // Make an API call to update the key-value pair on the server
    axios
      .post(
        `https://api15.ecommcube.com/_${searchSite}apidata/jtags_update`,
        null,
        {
          params: {
            [updateKey]: updateValue,
            pass: "billy",
          },
        }
      )
      .then((response) => {
        console.log(response.data); // Handle the response as needed
      })
      .catch((error) => {
        console.error(error); // Handle errors as needed
      });

    setUpdateKey("");
    setUpdateValue("");
    handleModal();
  };

  const handleSelect = (selected: React.SetStateAction<number>) => {
    setSearchSite(selected);
  };

  return (
    <>
      {modalType === "updateKeyValue" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleUpdateValue}
          className="max-w-2xl"
          title={undefined}
          modalType="updateKeyValue"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold mb-4">
              Update Key: {updateKey}
            </h2>
            <EditGuide />
          </div>
          <div className="grid gap-4">
            <div>
              <label htmlFor="new-key" className="inputLabel">
                Key Tag
              </label>
              <input
                disabled
                type="text"
                id="new-key"
                placeholder="Enter key tag"
                value={updateKey}
                onChange={(e) => setUpdateKey(e.target.value)}
                className="inputField mt-1"
              />
            </div>
            <div>
              <label className="inputLabel" htmlFor="updateValueInput">
                Key value:
              </label>
              <textarea
                id="updateValueInput"
                placeholder="Enter key value"
                className="inputField mt-1 h-64"
                value={updateValue}
                onChange={(e) => setUpdateValue(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      )}
      {modalType === "newKey" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={SubmitNewKey}
          className="max-w-2xl"
          title={undefined}
          modalType="newKey"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold mb-4">Create New Key Tag</h2>
            <EditGuide />
          </div>
          <div className="grid gap-4">
            <div>
              <label htmlFor="new-key" className="inputLabel">
                Enter New key
              </label>
              <input
                type="text"
                id="new-key"
                placeholder="Enter key tag"
                value={newKey}
                onChange={(event) => setNewKey(event.target.value)}
                className="inputField mt-1"
                required
              />
            </div>
            <div>
              <label htmlFor="new-value" className="inputLabel">
                New value
              </label>
              <textarea
                id="new-value"
                placeholder="Enter key value"
                className="inputField mt-1 h-64"
                value={newValue}
                onChange={(event) => setNewValue(event.target.value)}
                required
              />
            </div>
          </div>
        </Modal>
      )}
      {modalType === "deleteKey" && (
        <Modal
          modalType="delete"
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleConfirmDelete}
          className="max-w-lg "
          title={undefined}
        >
          <span className="text-lg text-center">
            {`Are you sure you want to delete the key "${keyToDelete}"?`}
          </span>
        </Modal>
      )}

      {/* Search Bar and Websites List Select */}
      <div className="flex items-center justify-between">
        <SelectWebsite
          handleSelect={handleSelect}
          label=""
          name="select"
          options={websites}
          className="w-72"
        />
        <h2 className="elementHeading3">{websites[searchSite].name}</h2>
        <div className="flex items-center space-x-3">
          <input
            type="text"
            placeholder="Search by tag or value"
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="rounded border shadow-sm focus:outline-none focus:ring-2 lg:w-72 focus:ring-yellow-600 focus:border-transparent px-5 py-3 disabled:opacity-50 disabled:cursor-not-allowed"
          />
          <button className="btnPrimary" onClick={handleCreateNewKey}>
            Add new tag
          </button>
        </div>
      </div>

      {/* Data Table */}
      {myData ? (
        <div className="w-full overflow-x-scroll h-[calc(100vh-230px)] mt-3">
          {" "}
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 shadow-sm ">
              <tr>
                <th scope="col" className="tableHead">
                  Sr.
                </th>
                <th scope="col" className="tableHead">
                  Actions
                </th>
                <th scope="col" className="tableHead">
                  Markdown
                </th>
                <th scope="col" className="tableHead">
                  Key
                </th>
                <th scope="col" colSpan={3} className="tableHead">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y text-gray-900 divide-gray-200 h-full overflow-y-scroll">
              {filteredData.map(([key, value], index) => (
                <tr key={key} className="hover:bg-gray-100">
                  <td className="px-6 py-3">
                    <div className="text-sm">{index + 1}</div>
                  </td>
                  <td className="px-6 py-3">
                    <span className="h-full w-full flex items-center">
                      <button
                        onClick={() => handleUpdateKey(key, value.toString())}
                        className="text-gray-400 hover:text-yellow-500"
                      >
                        <PencilSquareIcon className="h-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteKey(key)}
                        className="ml-2 text-gray-400 hover:text-red-400"
                      >
                        <TrashIcon className="h-4" />
                      </button>
                    </span>
                  </td>
                  <td className="px-6 py-3">
                    {isMarkdownTag(key) && (
                      <div
                        className="text-sm flex items-center"
                        title="Markdown Text"
                      >
                        <CheckBadgeIcon className="w-5 mr-1" />
                        Md
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-3">
                    <div className="text-sm">{key}</div>
                  </td>
                  <td colSpan={3} className="px-6 py-3">
                    <div className="text-sm">{value}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-72">
          <img src="/loading.gif" className="w-36" alt="" />
        </div>
      )}

      {/* Tabs */}
      <div className="flex items-center w-full border-t pt-5 gap-2">
        {contentType.map((button, index) => (
          <button
            key={index}
            onClick={() => setSearchQuery(button.type)}
            type="button"
            className={
              searchQuery === button.type
                ? "btnPrimary"
                : "bg-white btnPrimary hover:bg-yellow-500"
            }
          >
            {button.name}
          </button>
        ))}
      </div>
    </>
  );
}

function EditGuide() {
  return (
    <Menu as="div" className="relative">
      <td>
        <Menu.Button title="Text Editor Guide">
          <InformationCircleIcon className="w-7" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-0 mt-7 bg-white p-5 z-20 shadow-xl shadow-gray-300 rounded-lg w-80">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 shadow-sm ">
                <tr>
                  <th scope="col" className="tableHead">
                    Tag
                  </th>
                  <th scope="col" colSpan={2} className="tableHead">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white text-gray-900 divide-y divide-gray-200 h-full overflow-y-scroll">
                {tagsType.map((tag, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="p-3 py-2">
                      <div className="text-sm ">{tag.name}</div>
                    </td>
                    <td colSpan={2} className="p-3 py-2">
                      <div className="text-sm">{tag.des}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Menu.Items>
        </Transition>
      </td>
    </Menu>
  );
}
